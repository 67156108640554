import { useStaticQuery, graphql } from "gatsby";

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaDataQuery {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            siteOgpImgUrl
            internalFixedPagePath {
              about
              products
              list
              tags
              brands
              categories
              search
            }
            externalLinkUrl {
              mercari
              youtube
              discord
              x
            }
          }
        }
      }
    `
  );
  return site.siteMetadata;
};

export { useSiteMetadata };
