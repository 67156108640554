import * as React from "react";
// Link component from:
// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/
import { Link as GatsbyLink } from "gatsby";
import { usePageLoadingState } from "./page-loading-state-provider";

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  const { isPageLoading, setIsPageLoading } = usePageLoadingState();
  //console.log("-----hog!!----");
  //console.log(hoge);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        onClick={(event) => {
          // disable click with metakey
          if (event.metaKey || event.ctrlKey) {
            // Allow default browser behavior (open in new tab)
            return;
          }
          // get stacked if Link was clicked when the fetching is in progress
          if (isPageLoading) event.preventDefault();
          window.scrollTo({ top: 0 });
          setIsPageLoading(true);
        }}
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} rel="nofollow noopener noreferrer" {...other}>
      {children}
    </a>
  );
};

export { Link };
