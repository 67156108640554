import React, { createContext, useContext, useState } from "react";

// Create a context
const PageLoadingStateContext = createContext();

// Create a provider component
const PageLoadingStateProvider = ({ children }) => {
  const [isPageLoading, setIsPageLoading] = useState(false);

  return (
    <PageLoadingStateContext.Provider
      value={{ isPageLoading, setIsPageLoading }}
    >
      {children}
    </PageLoadingStateContext.Provider>
  );
};

const usePageLoadingState = () => {
  return useContext(PageLoadingStateContext);
};

export { PageLoadingStateProvider, usePageLoadingState };
