// dynamic transition reference
// https://conermurphy.com/blog/page-transition-animations-gatsbyjs-framer-motion/

import * as React from "react";
import ctl from "@netlify/classnames-template-literals";
import { AnimatePresence, motion } from "framer-motion";
import { Header } from "../shared/header";
import { Footer } from "../shared/footer";
import { PageLoadingStateProvider } from "./page-loading-state-provider";
import { PageLoadingIndicator } from "./page-loading-indicator";
import { usePageLoadingState } from "./page-loading-state-provider";

const InsideLayout = ({ children, path }) => {
  const { setIsPageLoading } = usePageLoadingState();
  return (
    <div className="relative">
      {/* gradient */}
      <div className="relative h-0">
        <div
          className={ctl(`
            absolute left-0 top-0 right-0
            zd-gradient-white-to-transparent
            h-[800px]
            opacity-10
          `)}
        ></div>
      </div>
      <div
        className={ctl(`
          min-h-screen
          bg-zd-black bg-opacity-90 transition-all
          2xl:ml-hgap-md
          px-hgap-sm md:px-hgap-md
          relative z-10
        `)}
      >
        <div className="max-w-[1800px]">
          <Header path={path} />
          <div className="pb-[180px]">
            <AnimatePresence mode="wait">
              <motion.main
                key={path}
                initial={{ opacity: 0, y: -10, ease: "easeInOut" }}
                animate={{ opacity: 1, y: 0, ease: "easeInOut" }}
                exit={{ opacity: 0 }}
                transition={{
                  type: "spring",
                  mass: 0.35,
                  stiffness: 75,
                  duration: 0.15,
                }}
                onAnimationComplete={() => {
                  setIsPageLoading(false);
                }}
              >
                {children}
              </motion.main>
            </AnimatePresence>
          </div>
          <div
            className={ctl(`
              absolute right-0 bottom-0 left-0
              h-[6em]
              px-hgap-sm md:px-hgap-md
            `)}
          >
            <Footer path={path} />
          </div>
        </div>
      </div>
    </div>
  );
};

const GlobalLayout = ({ children, path }) => {
  return (
    <PageLoadingStateProvider>
      <PageLoadingIndicator />
      <InsideLayout path={path}>{children}</InsideLayout>
    </PageLoadingStateProvider>
  );
};

export { GlobalLayout };
