import * as React from "react";
import { usePageLoadingState } from "./page-loading-state-provider";
import { css, keyframes } from "@emotion/css";
import tw from "twin.macro";

const load = keyframes`
  0% {
    width: 0;
  }
  50% {
    width: 40%;
    left: 30%;
  }
  100% {
    width: 40%;
    left: 60%;
  }
`;

const classNames = {
  div: css`
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    animation: ${load} 1s infinite;
    ${tw`bg-zd-black`}
  `,
};

const PageLoadingIndicator = () => {
  const { isPageLoading } = usePageLoadingState();
  return (
    <div className="h-[1px] w-full bg-zd-link fixed top-[1px] right-0 z-50">
      {isPageLoading && <div className={classNames.div}></div>}
    </div>
  );
};

export { PageLoadingIndicator };
