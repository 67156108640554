import * as React from "react";
import ctl from "@netlify/classnames-template-literals";
import RocketIcon from "../../assets/svgs/rocket.svg";
import { Link } from "./link";

const goUp = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const Footer = ({ path }) => {
  const isActiveTop = path === "/";
  const isActiveContact = path === "/contact/";
  return (
    <div
      className={ctl(`
        max-w-[1800px] pb-vgap-md
      `)}
    >
      <div
        className={ctl(`
          pt-vgap-md
          flex
          border-t border-dashed border-zd-gray
          xl:-ml-hgap-md-x2
          xl:pl-hgap-md-x2
          xl:-mr-hgap-md
          xl:pr-hgap-md
        `)}
      >
        <div
          className={ctl(`
          flex items-center
        `)}
        >
          <nav>
            <ul
              className={ctl(`
              flex flex-col md:flex-row
              gap-x-hgap-sm gap-y-vgap-xs
            `)}
            >
              <li className="whitespace-nowrap">
                <Link
                  to="/"
                  className="zd-invert-color-link"
                  activeClassName="pointer-events-none no-underline"
                  tabIndex={isActiveTop ? -1 : 0}
                >
                  takazudomodular.com
                </Link>
              </li>
              <li className="whitespace-nowrap flex">
                <span className="mr-hgap-xs hidden md:block">/</span>
                <Link
                  to="/contact/"
                  className="zd-invert-color-link"
                  activeClassName="pointer-events-none no-underline"
                  tabIndex={isActiveContact ? -1 : 0}
                >
                  お問い合わせ
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="w-full">
          <button
            className="flex text-zd-white zd-invert-color-link ml-auto items-center"
            onClick={goUp}
          >
            <span className="underline mr-[6px] hidden sm:block">
              ページの先頭へ
            </span>
            <RocketIcon className="h-[40px]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export { Footer };
