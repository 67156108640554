import * as React from "react";
import ctl from "@netlify/classnames-template-literals";
import TakazudoLogo from "../../assets/svgs/takazudo.svg";
import MercariLogo from "../../assets/svgs/mercari.svg";
import DiscordLogo from "../../assets/svgs/discord.svg";
import YoutubeLogo from "../../assets/svgs/youtube.svg";
import ArrowRight from "../../assets/svgs/arrow-right.svg";
import SearchIcon from "../../assets/svgs/search.svg";
import XLogo from "../../assets/svgs/x.svg";
import { useSiteMetadata } from "../../hooks/use-site-metadata";
import { Link } from "./link";

const GlobalNavInner = ({ items, path }) => {
  return (
    <ul
      className={ctl(`
        flex
        gap-x-[20px] md:gap-x-[24px] lg:gap-x-[20px]
      `)}
    >
      {items.map(({ label, href, key }) => {
        const isActive = path === href;
        return (
          <li
            className={ctl(`
              whitespace-nowrap
            `)}
            key={key}
          >
            <Link
              to={href}
              className={ctl(`
                inline-flex items-center zd-invert-color-link
                pt-[1px] pb-[2px] pr-[7px] pl-[5px]
                mt-[-1px] mb-[-2px] mr-[-7px] ml-[-5px]
                rounded-sm
                group
              `)}
              activeClassName={ctl(`
                pointer-events-none text-zd-white
                text-shadow-none decoration-2
                is-active no-underline
              `)}
              tabIndex={isActive ? -1 : 0}
            >
              <span className="pr-[7px] pt-[3px]">
                <ArrowRight className="w-[18px] 2xl:w-[24px]" />
              </span>
              <span className="pt-1px border-zd-white group-[.is-active]:border-b border-dotted">
                {label}
              </span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

const GlobalNav = ({ items, path }) => {
  return (
    <nav
      className={ctl(`
        flex flex-col
        gap-[8px]
        text-xs xl:text-sm
      `)}
    >
      <GlobalNavInner items={[items[0], items[1]]} path={path} />
      <GlobalNavInner items={[items[2], items[3]]} path={path} />
    </nav>
  );
};

const LogoNavList = ({ logoNavInfo = [] }) => {
  return (
    <>
      {logoNavInfo.map(({ logo, href }) => {
        return (
          <li key={href}>
            <a
              href={href}
              className={ctl(`
                flex items-center justify-center
                zd-invert-color-link
                w-[40px] h-[40px]
                2xl:w-[64px] 2xl:h-[64px]
                p-[6px] m-[-6px]
                box-content
              `)}
            >
              {logo}
            </a>
          </li>
        );
      })}
    </>
  );
};

const Header = ({ path }) => {
  const { externalLinkUrl, internalFixedPagePath } = useSiteMetadata();
  const logoNavInfo = [
    {
      logo: (
        <MercariLogo
          className="w-[40px] lg:w-[36px] xl:w-[64px]"
          aria-labelledby="icon-mercari"
          role="img"
        />
      ),
      href: externalLinkUrl.mercari,
    },
    {
      logo: (
        <DiscordLogo
          className="w-[40px] lg:w-[36px] xl:w-[64px]"
          aria-labelledby="icon-discord"
          role="img"
        />
      ),
      href: externalLinkUrl.discord,
    },
    {
      logo: (
        <YoutubeLogo
          className="w-[40px] lg:w-[36px] xl:w-[64px]"
          aria-labelledby="icon-youtube"
          role="img"
        />
      ),
      href: externalLinkUrl.youtube,
    },
    {
      logo: (
        <XLogo
          className="w-[30px] lg:w-[26px] xl:w-[54px]"
          aria-labelledby="icon-x"
          role="img"
        />
      ),
      href: externalLinkUrl.x,
    },
  ];
  const globalNavItems = [
    {
      key: 1,
      label: "このWebサイトは何",
      href: internalFixedPagePath.about,
    },
    { key: 2, label: "商品一覧", href: internalFixedPagePath.products },
    { key: 3, label: "ブランド一覧", href: internalFixedPagePath.brands },
    { key: 4, label: "記事一覧", href: internalFixedPagePath.list },
  ];
  return (
    <div
      className={ctl(`
        pt-hgap-md
        pb-hgap-md
        mb-hgap-md
        flex items-stretch
        flex-col md:flex-row
        border-dashed border-zd-gray border-b
        xl:-mr-hgap-md
        xl:pr-hgap-md
      `)}
    >
      <div className="flex flex-col lg:flex-row items-center md:items-stretch">
        <Link
          to="/"
          className="zd-invert-color-link no-underline flex"
          activeClassName="pointer-events-none"
          tabIndex={path === "/" ? -1 : 0}
        >
          <h1
            className={ctl(`
              text-xl md:text-2xl lg:text-lg xl:text-2xl 2xl:text-3xl
              flex items-center flex-initial
              transition-all
            `)}
          >
            <TakazudoLogo
              className={ctl(`
                w-[50px] h-[50px]
                xl:w-[80px] xl:h-[80px]
              `)}
            />
            <span className="pl-[20px] pr-[30px] whitespace-nowrap block">
              Takazudo Modular
            </span>
          </h1>
        </Link>

        <div className="flex pt-[20px] lg:pt-0 items-stretch">
          <div
            className={ctl(`
              border-l-0
              border-r border-dashed border-zd-gray pr-[20px] md:pr-[40px] flex-initial
              lg:border-l lg:pl-[30px]
            `)}
          >
            <GlobalNav items={globalNavItems} path={path} />
          </div>
          <div
            className={ctl(`
              flex items-stretch flex-initial
              border-zd-gray border-dashed
              lg:border-r
            `)}
          >
            <a
              href={internalFixedPagePath.search}
              className={ctl(`
                flex items-center zd-invert-color-link
                pl-[20px]
                px-[20px]
              `)}
            >
              <SearchIcon className="w-[20px] xl:w-[40px] ml-auto" />
            </a>
          </div>
        </div>
      </div>
      <div
        className={ctl(`
          pl-0
          pt-vgap-sm sm:pt-0
          hidden
          md:flex items-center justify-center
          flex-auto
          lg:pl-hgap-sm
        `)}
      >
        <ul
          className={ctl(`
            pl-0 gap-[20px]
            items-center
            hidden
            md:grid grid-cols-4 sm:grid-cols-2 lg:grid-cols-4 sm:ml-auto
            text-white
          `)}
        >
          <LogoNavList logoNavInfo={logoNavInfo} />
        </ul>
      </div>
    </div>
  );
};

export { Header };
